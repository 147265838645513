





























import BaseButton from '@/components/base/BaseButton.vue';
import { useAuthStore } from '@/composables/useAuthStore';
import { getBookedReservations } from '@/composables/useBookedResources/useBookedReservations';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { Reservation } from '@/models/booked/Reservation';
import { computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router/useRouter';
import { isBefore, parseISO } from 'date-fns';
import ReservationCard from '../MyBookingsPage/components/ReservationCard.vue';
import { formatDate } from '@/utils/date';

export default defineComponent({
  components: { BaseButton, MemberPage, ReservationCard },
  name: 'DashboardPage',
  setup() {
    const { user, isMember } = useAuthStore();

    const { router } = useRouter();

    const reservations = ref<Reservation[]>([]);

    const filteredReservations = computed(() => {
      const now = new Date();

      return reservations.value
        .filter((res: Reservation) => {
          return !isBefore(parseISO(res.startDate), now);
        })
        .slice(0, 3);
    });

    onMounted(async () => {
      reservations.value = await getBookedReservations();
    });

    return {
      user,
      editProfile: () => router.push({ name: routeNames.profile.details }),
      createBooking: () => router.push({ name: routeNames.spaces.book }),
      filteredReservations,
      routeNames,
      formatDate,
      isMember,
    };
  },
});
